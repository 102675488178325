<template>
  <div>
    <div class="card" :class="{ card1: customer==='qingtian' }">
      <div class="title">
        <div class="left">
          <img
            v-if="customer==='qingtian'"
            src="@/assets/images/qingtian/基本信息.png"
            alt=""
          />
          <span v-if="customer==='jinlang'"></span>&nbsp;基本信息
        </div>
      </div>
      <div class="content">
        <ul>
          <li>房屋高度（m）</li>
          <li>{{ baseInfo.HouseHeight }}</li>
        </ul>
        <ul>
          <li>房屋朝向</li>
          <li>{{ baseInfo.HouseFacing }}</li>
        </ul>
        <ul v-if="baseInfo.HouseFacing !== '正南'">
          <li>偏向度数</li>
          <li>{{ baseInfo.FacingDegree }}</li>
        </ul>
        <ul>
          <li>距并网点距离（m）</li>
          <li>{{ baseInfo.GridConnectionDistance }}</li>
        </ul>
        <ul>
          <li>屋脊高度（m）</li>
          <li>{{ baseInfo.RidgeHeight }}</li>
        </ul>
        <ul>
          <li>南平台厚度（m）</li>
          <li>{{ baseInfo.SouthPlatformThickness }}</li>
        </ul>
        <ul>
          <li>南平台落差（m）</li>
          <li>{{ baseInfo.SouthPlatformDrop }}</li>
        </ul>
        <ul>
          <li>是否有北平台</li>
          <li>{{ baseInfo.IsHaveNorthPlatform }}</li>
        </ul>
        <div
          style="margin-bottom: 15px"
          v-if="baseInfo.IsHaveNorthPlatform == '有'"
        >
          <ul>
            <li>北平台厚度（m）</li>
            <li>{{ baseInfo.NorthPlatformThickness }}</li>
          </ul>
          <ul>
            <li>北平台落差（m）</li>
            <li>{{ baseInfo.NorthPlatformDrop }}</li>
          </ul>
        </div>

        <ul>
          <li>屋面类型</li>
          <li>{{ baseInfo.RoofingType }}</li>
        </ul>
        <ul
          v-if="baseInfo.RoofingType == '预制板' || baseInfo.RoofingType == '现浇板'"
        >
          <li>屋面厚度（m）</li>
          <li>{{ baseInfo.RoofSlabThickness }}</li>
        </ul>
        <div style="margin-bottom: 15px" v-if="baseInfo.RoofingType == '木望板'">
          <ul>
            <li>木檩直径（m）</li>
            <li>{{ baseInfo.WoodPurlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩间距（CM）</li>
            <li>{{ baseInfo.WoodPurlinSpacing }}</li>
          </ul>
          <ul>
            <li>木望板厚度（m）</li>
            <li>{{ baseInfo.WoodWatchboardThickness }}</li>
          </ul>
        </div>
        <div style="margin-bottom: 15px" v-if="baseInfo.RoofingType == '檩橼结构'">
          <ul>
            <li>木檩条直径（m）</li>
            <li>{{ baseInfo.PurlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩条间距（m）</li>
            <li>{{ baseInfo.PurlinSpacing }}</li>
          </ul>
          <ul>
            <li>人字梁东西跨度（m）</li>
            <li>{{ baseInfo.HerringboneBeamEastWest }}</li>
          </ul>
          <ul>
            <li>人字梁南北跨度（m）</li>
            <li>{{ baseInfo.HerringboneBeamNorthSouth }}</li>
          </ul>
          <ul>
            <li>人字梁主梁直径（m）</li>
            <li>{{ baseInfo.HerringboneGirderDiameter }}</li>
          </ul>
        </div>
        <ul>
          <li>安装方式</li>
          <li>{{ baseInfo.InstallationMethod }}</li>
        </ul>
      </div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancelEvent">取消</div>
<!--      <div class="addcancel" @click="save" :disabled="stopEnd">保存数据</div>-->
      <div class="determine" @click="submit">提交踏勘信息</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive, toRefs, getCurrentInstance } from 'vue'

export default {
  setup() {
    const router = useRouter()
    let baseInfo = {}
    const temporary = JSON.parse(sessionStorage.getItem('baseInfo'))
    if (JSON.parse(sessionStorage.getItem('requestOuterRoof')).hasOwnProperty('isTrue')) {


    }

    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      customer: window.customer,
      toast: null,
      timer: null,
      second: 40,
      stopEnd: false,
      canvasImg: sessionStorage.getItem('innerCanvas'),
      obj1: ''
    })

    const methodsMap = {
      cancelEvent() {
        this.$router.back(-1)
      },
      getDate() {
        var datetime = new Date();
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        return year + '/' + month + '/' + date
      },
      submit() {
        router.push('/confirmLayout')
      }
    }
    return { ...toRefs(data), baseInfo, ...methodsMap }
  }
}
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.card {
  margin: 0 auto;
  margin-top: 20px;
  padding: 16px 12px;
  width: 343px;
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  min-height: 127px;
  .title {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(199, 201, 204, 0.4);
    .left {
      display: flex;
      align-items: center;
        img {
        width: 25px;
        height: 25px;
      }
    }
    span {
      display: inline-block;
      width: 4px;
      height: 19px;
      background: #04cc66;
    }
  }
  .content {
    margin-top: 15px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 14px;
    color: #7c8190;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
     li:nth-last-child(1) {
      font-weight: bold;
      color: #1a1a1a;
    }
  }
}
.card1 {
  margin-top: 12px;
  border-radius: 0;
  width: 375px;
  padding: 12px 16px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 28px;
  .cancel,
  .determine {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .cancel {
    width: 118px;
    border: 1px solid #04cc66;
    color: #04cc66;
  }
  .determine {
    width: 217px;
    background: #04cc66;
  }
}
</style>
